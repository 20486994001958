<template>
  <div class="footer-warp">
    <ul class="footer-content">
      <li class="logo item">
        <img src="../../assets/logo.png" alt="logo" />
        <div class="logo-text"></div>
      </li>
      <li class="item">联系方式：aml2077@163.com</li>
      <li class="item">公司地址：深圳市龙岗区龙城街道新联社区爱南路468-4号206-C01</li>
    </ul>
    <p class="tips">
      Copyright 2023 艾米罗科技（深圳）有限公司 All Rights Reserved.
      <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备18066307号-2</a>
    </p>
  </div>
</template>

<script>
export default {
  name: 'FooterBottom',
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.footer-warp {
  background: #161616;
  .footer-content {
    max-width: 1300px;
    margin: 0 auto;
    padding: 78px 0 69px 0;
    margin-bottom: 5px;
    border-bottom: 2px solid rgba(255, 255, 255, 0.05);
    font-family: PingFang SC, PingFang SC;
    font-weight: 300;
    font-size: 16px;
    color: #FFFFFF;
    > :last-child {
      margin-top: 5px;
    }
  }
  .tips {
    text-align: center;
    margin: 0;
    overflow: hidden;
    padding-bottom: 2px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #808080;
    a {
      color: #808080;
    }
  }
  .logo {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  .logo-text {
    display: inline-block;
    background: url('../../assets/logo_text-w.png') no-repeat center;
    background-size: cover;
    height: 28px;
    width: 153px;
    margin-left: 15px;
  }
}
</style>
