<template>
  <div class="header-wrap">
    <div class="header-bg">
      <div class="header-content-wrapper">
        <ul class="header-content">
        <li class="logo">
          <img src="../../assets/logo.png" alt="logo" />
          <div class="logo-text"></div>
        </li>
        <li class="nav">
          <el-menu
            :default-active="activeMenu"
            mode="horizontal"
            background-color="transparent" 
            :router="router"
            color="#ffffff">
            <el-menu-item :index="item.path" v-for="item in menuItemList" :key="item.path">{{
              item.name
            }}</el-menu-item>
          </el-menu>
        </li>
      </ul>
      </div>
   
      <ul class="image-box">
        <li class="box-left">
          <img src="../../assets/home_c.png" />
        </li>
        <li class="box-right">
          <img src="../../assets/logo_text_b.png" class="logo_text_b" />
          <span class="desc_text"
            >【九九交易行】是拥有官方认证的专业传奇游戏交易平台,目前与市场上大量的传奇游戏达成了深度合作，打通了技术壁垒，实现了安全高效的角色交易功能，保障用户的交易安全，永久承诺找回包赔。我们团队一直致力于打造安全、高效、专业的游戏虚拟道具交易平台，为用户提供专业、优质的交易体验。</span
          >
          <div class="down_btn" @click="downloadAndroid">
            <img src="../../assets/an.png" />
            <a>Android下载</a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeaderNav',
  computed: {
    activeMenu() {
      const route = this.$route;
      const { meta, path } = route;
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
  },
  data() {
    return {
      router: true,
      menuItemList: [
        {
          name: '首页',
          path: '/home',
        },
      ],
    };
  },
  methods: {
    downloadAndroid () {
      const link = document.createElement('a');
      link.href = this.downloadUrl;
      link.download = '九九交易行APP';
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
