<template>
  <div>
    <header-nav></header-nav>
    <!-- 底部 -->
    <footer-bottom></footer-bottom>
  </div>
</template>

<script>
import HeaderNav from '@/components/Header/index';
import FooterBottom from '@/components/Footer/index';

export default {
  name: 'Home',
  components: {
    HeaderNav,
    FooterBottom,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
